
import './App.css';
import React from 'react';

import photo_1 from './photos/photo_1.jpg'
import photo_2 from './photos/photo_2.jpg'
import photo_3 from './photos/photo_3.jpg'
import photo_4 from './photos/photo_4.jpg'
import photo_5 from './photos/photo_5.jpg'
import photo_6 from './photos/photo_6.jpg'
import photo_7 from './photos/photo_7.jpg'
import photo_8 from './photos/photo_8.jpg'
import photo_9 from './photos/photo_9.jpg'
import photo_10 from './photos/photo_10.jpg'
import photo_11 from './photos/photo_11.jpg'
import photo_12 from './photos/photo_12.jpg'
import photo_13 from './photos/photo_13.jpg'
import photo_14 from './photos/photo_14.jpg'
import photo_15 from './photos/photo_15.jpg'

const messages = [
  '',
  'Lastly, this wouldnt be a proper gift for Sophia\n without a pic of Reese being Reese!!! 🐶',
  '"Yeah sure lets put fish flakes on avocado toast"',
  'Hm I wonder what color that shirt is.\n  Also whats the capital of Alaska? 🤔',
  'We did a thing in Lancaster. Whoppie!!!',
  'Looking (and feeling) hawt in Sevilla! 🥵💃',
  'Seeing tall pointy tings 🍏',
  'Just landed with jet lag, cider, and a terrible haircut 🍾💇',
  ''
]

const turnRight = () => {
    if (si == 0){
      return
    }
    if(si>=1){
        si--;
    }
    else{
        si=right.length-1;
        function sttmot(i){
            setTimeout(() => {right[i].style.zIndex="auto"},300);
        }
        for(let i=0;i<right.length;i++){
            right[i].className="right";
            sttmot(i);
            z=1;
        }
    }
    console.log(si)
    setTimeout(() => {
      let message = document.getElementsByClassName("photomessage")[0];
      message.innerText = messages[si]
    },350);
  
    right[si].classList.add("flip");
    z++;
    right[si].style.zIndex=z;
}

const turnLeft = () => {
  console.log(si)
  if (si == null || si >= messages.length ){
    return
  }
  if(si<right.length) {
      si++;
  }
  else{
      si=1;
      for(let i=right.length-1;i>0;i--){
          right[i].classList.add("flip");
          right[i].style.zIndex=right.length+1-i;
      }
  }
  right[si-1].className="right";
  console.log(si)
  setTimeout(() => {
    let message = document.getElementsByClassName("photomessage")[0];
    if (messages[si] != undefined){
      message.innerText = messages[si]
    }
  },350);
  setTimeout(() => {right[si-1].style.zIndex="auto"},350);
}

let right = document.getElementsByClassName("right");
let si = null;
let z = 1


function App() {
  return (
    <div className="App">
      <div className="book-section">
      <div className="container">
            <div className="right">
              <figure className="back" id="back-cover">
                <br/>
                <h3>#sophiaturns26.com</h3>
                <h3>#thisgiftischeesyaf</h3>
                <h3>#weneedtotakemorepics</h3>
                <h3>#reesycutie</h3>
                <h3>#achievingthings</h3>
                <h3>#heelbackpasslastgame</h3>
                <h3>#outerspaceisnotnature</h3>
                <h3>#fishflakesonabagel</h3>
              </figure>
              <figure className="front">
                <img src={photo_15}></img>
              </figure>
            </div>
            <div className="right">
              <figure className="back">
                <img src={photo_14}></img>
              </figure>
              <figure className="front">
                <img src={photo_13}></img>
              </figure>
            </div>
            <div className="right">
              <figure className="back">
                <img src={photo_12}></img>
              </figure>
              <figure className="front">
                <img src={photo_11}></img>
              </figure>
            </div>
            <div className="right">
              <figure className="back">
                <img src={photo_10}></img>
              </figure>
              <figure className="front">
                <img src={photo_9}></img>
              </figure>
            </div>
            <div className="right">
              <figure className="back">
                <img src={photo_8}></img>
              </figure>
              <figure className="front">
                <img src={photo_7}></img>
              </figure>
            </div>
            <div className="right">
              <figure className="back">
                <img src={photo_6}></img>
              </figure>
              <figure className="front">
                <img src={photo_5}></img>
              </figure>
            </div>
            <div className="right">
              <figure className="back">
                <img src={photo_4}></img>
              </figure>
              <figure className="front">
                <img src={photo_3}></img>
              </figure>
            </div>
            <div className="right">
              <figure className="back">
                <img src={photo_2}></img>
              </figure>
              <figure className="front">
                <img src={photo_1}></img>
              </figure>
            </div>
            <div className="right">
              <figure className="back">
                <br />
                <p className="card">Sophia,</p>
                <p className="card">
                  You're a wonderful person and I am very lucky to be by your side! Happy twenty five and may all of your birthday wishes come true.
                  I love you very much and hope you enjoy this mini photo book
                </p>

                <p className="signature">Carlos</p>

              </figure>
              <figure className="front" id="cover">
                <br/>
                <h1>IT'S YA BIRTHDAY!! 🎉🎉🎉</h1>
              </figure>
            </div>
        </div>
        <p className="photomessage"></p>
        <button onClick={() =>turnLeft()}>Prev</button>
        <button onClick={() =>turnRight()}>Next</button>
        <br/>
      </div>
    </div>);
}

export default App;

